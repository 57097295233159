// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import $ from 'jquery'
import lightbox from 'lightbox2'
import Swiper, { Pagination, Autoplay, EffectFade } from 'swiper'

// import 'swiper/swiper-bundle.css'
// import 'lightbox2/dist/css/lightbox.css'

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

window.$ = $

// =================================================================================================
//   Setup lightbox
// =================================================================================================
$(() => {
  lightbox.option({
    albumLabel: '%1 / %2',
    fadeDuration: 500,
    imageFadeDuration: 500,
    resizeDuration: 500,
  })
})

// =================================================================================================
//   Change header fixed state
// =================================================================================================
$(() => {
  const $window = $(window)

  document.body.classList.toggle('fixed-header', $window.width() <= 576)

  $(window).on('resize', () => {
    document.body.classList.toggle('fixed-header', $window.width() <= 576)
  })
})

// =================================================================================================
//   Popup menu
// =================================================================================================
$(function () {
  $('header > nav > ul').on({
    mouseenter: function () { $(this).children('.submenu').fadeIn('fast') },
    mouseleave: function () { $(this).children('.submenu').fadeOut('fast') }
  }, 'li.has-submenu')

  $('article > .detail > .detail-group > .button.has-popup, .list-card__item > .detail > .detail-group > .button.has-popup').on({
    mouseenter: function () { $(this).next('.popup').fadeIn('fast') },
    click: function () {
      if ($(this).next('.popup').hasClass('sp-visible')) {
        $(this).next('.popup').removeClass('sp-visible').fadeOut('fast')
      } else {
        $(this).next('.popup').addClass('sp-visible').fadeIn('fast')
      }
    }
  })

  $('article > .detail > .detail-group, .list-card__item > .detail > .detail-group').on({
    mouseleave: function () { $(this).children('.popup').fadeOut('fast') },
  })
})

// =================================================================================================
//   MV Slider
// =================================================================================================
$(() => {
  new Swiper('.mv-slider', {
    modules: [Pagination, Autoplay, EffectFade],
    effect: 'fade',
    speed: 3000,
    loop: true,
    autoplay: { delay: 5000 },
    pagination: {
      el: '.pagination',
      bulletClass: 'bullet',
      bulletActiveClass: 'active',
    },
  })
})

// =================================================================================================
//   SP menu
// =================================================================================================
var spmenu = {
  __menuElement: $('.spmenu'),

  __init: function () {
    this.__menuElement = $('.spmenu')

    $('.spmenu-opener').off('click').on('click', () => { this.open() })
    $('.spmenu-closer').off('click').on('click', () => { this.close() })
  },
  open: function () {
    document.body.classList.add('scroll-lock')
    this.__menuElement.addClass('visible')
  },
  close: function () {
    document.body.classList.remove('scroll-lock')
    this.__menuElement.removeClass('visible')
  },
}


$(() => {
  spmenu.__init()

  $('a').each((index, item) => {
    if (item.hash !== '') {
      $(item).on('click', () => {
        let scrollTop = $(item.hash).offset().top

        if ($('body').hasClass('fixed-header')) {
          scrollTop -= $('header').outerHeight()
        }

        if ($('.spmenu').hasClass('visible')) {
          spmenu.close()
        }

        $('html, body').animate({ scrollTop }, 400, 'swing')
        return false
      })
    }
  })
})

// =================================================================================================
//   Smooth scroll to anchor
// =================================================================================================
$(() => {
  if (window.location.hash !== '') {
    let scrollTop = $(window.location.hash).offset().top

    if ($('body').hasClass('fixed-header')) {
      scrollTop -= $('header').outerHeight()
    }

    $('html, body').animate({ scrollTop }, 400, 'swing')
    return false
  }
})

$(window).on('load', () => {
  if (window.location.hash !== '') {
    let scrollTop = $(window.location.hash).offset().top

    if ($('body').hasClass('fixed-header')) {
      scrollTop -= $('header').outerHeight()
    }

    if ($('.spmenu').hasClass('visible')) {
      spmenu.close()
    }

    $('html, body').animate({ scrollTop }, 400, 'swing')
    return false
  }
})

// =================================================================================================
//   Setup Requirements content
// =================================================================================================
$(() => {
  $('.requirements-container.collapsable').on('click', '.requirement-title', function () {
    $(this).next('.requirement-content').slideToggle({
      start: function () {
        $(this).closest('.requirement-group').toggleClass('expanded')
      }
    })
  })
})

// =================================================================================================
//   Accordion Toggle
// =================================================================================================
$(function() {
  $(".accordion-toggle").on("click", function() {
    $(this).next().slideToggle();
  })
})

// =================================================================================================
//   Form group collapse
// =================================================================================================
$(() => {
  $('form input[type=radio]').on('change', function () {
    const target = $(this).closest('.col-sm-8').next().find('.hidden-box')

    if (this.value === target[0].dataset.displayValue) {
      target.slideDown('fast')
    } else {
      target.slideUp('fast')
    }
  })
})

// =================================================================================================
//   Facility gallery
// =================================================================================================
$(() => {
  $('.facility-gallery').on('click', 'ul > .thumbnail', function () {
    const beforeThumbnail = $(this).closest('.thumbnails').children('.active')
    beforeThumbnail.removeClass('active')
    $(this).addClass('active')

    const pictureFrame = $(this).closest('.facility-gallery').find('.frame')
    const beforePicture = pictureFrame.children('.picture.active')
    const afterPicture = pictureFrame.children(`.picture[data-index=${this.dataset.index}]`)
    beforePicture.removeClass('active')
    afterPicture.addClass('active')
  })
})

// =================================================================================================
//   FAQ collapse
// =================================================================================================
$(() => {
  $('.faq-ac dt').on('click', function () { $(this).next().slideToggle() })
})
